import * as Sentry from '@sentry/gatsby';

if (['production', 'staging'].includes(process.env.NODE_ENV as string)) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'https:staging.dev.biograph.com',
          'https://biograph.com',
          'https://www.biograph.com',
        ],
      }),
      new Sentry.Replay(),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
